<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="title">
                    <h1>user override</h1>
                </div>
            </div>
            <div class="row">
                <form @submit.prevent="overrideUser" class="form-group col-md-4" style="padding-left: 0;">
                    <v-autocomplete
                        placeholder="Enter Username"
                        :min-len="2"
                        v-model="client"
                        :items="clients"
                        :get-label="getLabel"
                        :component-item='itemTemplate'
                        @update-items="updateClientList"
                        @item-selected="clientSelected"
                    ></v-autocomplete>
                    <button
                        style="margin-top: 20px;"
                        class="btn"
                        type="submit"
                        :disabled="!(client && client.id)"
                    >
                        submit
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"

	import ClientItemTemplate from '../components/common/reusable/ClientItemTemplate'

	export default {
		name: "UserOverride",
        data() {
			return {
				clients: [],
				client: null,
				itemTemplate: ClientItemTemplate,
            }
        },
		computed: {
			...mapGetters({
				role: 'userManage_orders',
				btoken: 'getBillinkToken'
			})
		},
        methods: {
	        updateClientList(val) {
		        billinkAxios
			        .post('app/clients/list', { username: val })
			        .then(({data}) => this.clients = data.clients)
			        .catch(err => console.error(err))
	        },
	        clientSelected(val) {
		        this.client = val
	        },
	        getLabel (item) {
		        return !!item ? item.username : ''
	        },
	        overrideUser() {
	        	if (this.client && this.client.id) {
                    let params = { client: this.client.id }

                    billinkAxios
                        .get('app/override-client', { params })
                        .then(({data}) => {
	                        if (data.details && !!data.details.is_super && !!data.details.connected_users) {
		                        data.details.computedConnectedUsers = Object
			                        .keys(data.details.connected_users)
			                        .map(item => {
				                        return data.details.connected_users[item]
			                        })
	                        }
                            this.$store.dispatch('setBillinkToken', data)
                                .then((data) => {
	                                let logData = {
		                                username: data.details.username,
		                                email: data.details.email,
		                                id: data.details.id,
		                                role: this.role,
		                                billinkToken: data.access_token
	                                }

                                    this.$store.dispatch('disputeLogIn', logData)
	                                    .catch(err => console.error(err))
	                                    .then(() => window.location.href = '/')
                                })
                        })
                        .catch(err => console.error(err))
		        } else {
			        EventBus.$emit('showAlertModal', 'Pick a user first')
                }
            }
        },
        components: {
	        ClientItemTemplate
        },
	}
</script>

<style scoped>

</style>